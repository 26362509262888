import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className='overlay'>
            <div className='spinner' />
        </div>
    );
}

export default LoadingSpinner;