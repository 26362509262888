import React, {useState} from 'react';
import NewChatButton from './NewChatButton';
import ListItem from '../Sidebard/ListItem';
import DeleteConversationsButton from './DeleteConversations';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedConversationId } from '../Dashboard/dashboardSlice';

const Sidebar = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    const conversations = useSelector((state) => state.dashboard.conversations);

    const handleSetSelectedChat = (id) => {
        dispatch(setSelectedConversationId(id));
    }   

    return (
        <>
            <div className='navbar'>
                <div className={`toggle ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={`sidebar_container ${isOpen && "open"}`}>
                <NewChatButton handleSetSelectedChat={handleSetSelectedChat}/>
                {
                    conversations.map((c) => (<ListItem 
                        key={c.id}
                        title={c.messages[0].content}
                        conversationId={c.id}
                        handleSetSelectedChat={handleSetSelectedChat}
                    />))
                }
                <DeleteConversationsButton />
            </div>
        </>
    )
}

export default Sidebar;